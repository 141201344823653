// Generated by Framer (2923eb5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["CkKRhf8Je", "SI2KMlkr2"];

const serializationHash = "framer-6wQ1r"

const variantClassNames = {CkKRhf8Je: "framer-v-1opu9ox", SI2KMlkr2: "framer-v-51jto0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 2.7, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "CkKRhf8Je", "Variant 2": "SI2KMlkr2"}

const getProps = ({darkBackground, height, id, normalBackground, width, ...props}) => { return {...props, Q728sSGnL: darkBackground ?? props.Q728sSGnL ?? "var(--token-181e673f-09b4-4828-8087-edda310aa66a, rgb(32, 32, 32))", qmZ0MP7l1: normalBackground ?? props.qmZ0MP7l1 ?? "var(--token-652a6be1-7a0b-4b06-ad31-a244ff032ff8, rgb(227, 227, 227))", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "CkKRhf8Je"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;normalBackground?: string;darkBackground?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, qmZ0MP7l1, Q728sSGnL, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "CkKRhf8Je", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1opu9ox", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"CkKRhf8Je"} ref={ref ?? ref1} style={{backgroundColor: qmZ0MP7l1, ...style}} variants={{SI2KMlkr2: {backgroundColor: Q728sSGnL}}} {...addPropertyOverrides({SI2KMlkr2: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6wQ1r.framer-190aalr, .framer-6wQ1r .framer-190aalr { display: block; }", ".framer-6wQ1r.framer-1opu9ox { height: 580px; overflow: hidden; position: relative; width: 1016px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 580
 * @framerIntrinsicWidth 1016
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"SI2KMlkr2":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"qmZ0MP7l1":"normalBackground","Q728sSGnL":"darkBackground"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerheygpIN7q: React.ComponentType<Props> = withCSS(Component, css, "framer-6wQ1r") as typeof Component;
export default FramerheygpIN7q;

FramerheygpIN7q.displayName = "backgroundChangerMaster";

FramerheygpIN7q.defaultProps = {height: 580, width: 1016};

addPropertyControls(FramerheygpIN7q, {variant: {options: ["CkKRhf8Je", "SI2KMlkr2"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, qmZ0MP7l1: {defaultValue: "var(--token-652a6be1-7a0b-4b06-ad31-a244ff032ff8, rgb(227, 227, 227)) /* {\"name\":\"paraligoLeuko\"} */", title: "normalBackground", type: ControlType.Color}, Q728sSGnL: {defaultValue: "var(--token-181e673f-09b4-4828-8087-edda310aa66a, rgb(32, 32, 32)) /* {\"name\":\"Prime Black\"} */", title: "DarkBackground", type: ControlType.Color}} as any)

addFonts(FramerheygpIN7q, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})